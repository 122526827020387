export default [
  {
    key: 'regionId',
    label: 'field.region',
    rules: 'required',
    type: 'async-single-selection',
    repository: 'region',
    selectionKey: 'id',
    selectionLabel: 'regionCode',
    disabled: true,
  },
  {
    key: 'agentCode',
    label: 'field.agentCode',
    rules: 'required|max:10',
    type: 'text',
    disabled: true,
  },
  {
    key: 'nameKm',
    label: 'field.nameKm',
    type: 'text',
    disabled: true,
  },
  {
    key: 'nameEn',
    label: 'field.nameEn',
    type: 'text',
    disabled: true,
  },
  {
    key: 'addressKm',
    label: 'field.addressKm',
    type: 'textarea',
    disabled: true,
  },
  {
    key: 'addressEn',
    label: 'field.addressEn',
    type: 'textarea',
    disabled: true,
  },
  {
    key: 'contactNumber',
    label: 'field.contactNumber',
    type: 'tel',
    disabled: true,
  },
  {
    label: 'breadcrumb.vattanacBankAccount',
    icon: 'CreditCardIcon',
    type: 'divider',
  },
  {
    key: 'vbPath',
    label: 'field.vbPath',
    rules: 'max:200',
    type: 'text',
    cols: 12,
  },
  {
    key: 'vbUsername',
    label: 'field.vbUsername',
    rules: 'max:200',
    type: 'text',
  },
  {
    key: 'vbPassword',
    label: 'field.vbPassword',
    rules: 'max:200',
    type: 'password',
  },
  {
    key: 'vbPrivateKey',
    label: 'field.vbPrivateKey',
    rules: 'max:3000',
    type: 'textarea',
    cols: 12,
  },
  {
    label: 'breadcrumb.accountInfomration',
    icon: 'LockIcon',
    type: 'divider',
  },
  {
    key: 'username',
    label: 'field.username',
    rules: 'required',
    type: 'text',
    cols: 6,
  },
  {
    hide: true,
    skip: true,
    cols: 6,
  },
  {
    key: 'password',
    name: 'new-password',
    label: 'field.password',
    rules: 'required|min:6',
    type: 'password',
    hideInView: true,
  },
  {
    key: 'retypePassword',
    label: 'field.retypePassword',
    rules: 'required|confirmed:password',
    type: 'password',
    hideInView: true,
  },
  {
    key: 'isEnable',
    label: 'field.active',
    rules: '',
    type: 'checkbox',
    cols: 'auto',
  },
]
